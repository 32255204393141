import { Fragment, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import ACFImage from 'types/ACFImage';

import './styles/default-page.scss';

type HeroDefaultPageProps = {
  background_image: ACFImage;
  className?: string;
  copy?: string;
  imageBeforeText?: boolean,
  imageDesktopOnly?: any,
  imageFillBackground?: string;
  sub_title: string;
  title?: string;
};

export const HeroDefaultPage = ({
  background_image,
  className,
  title,
  sub_title,
  copy,
  imageBeforeText,
  imageFillBackground,
  imageDesktopOnly,
}:HeroDefaultPageProps) => {
  const [showImage, setShowImage] = useState(true);
  const breakpoints = useBreakpoint();

  useEffect(() => {
    imageDesktopOnly && breakpoints.sm ? setShowImage(false) : setShowImage(true);
  }, [breakpoints]);

  return (
    <section className={ `default-page-hero-section section ${className ?? ''}` }>
      <div className="container">
        <div className="hero-content">
          {sub_title && title === undefined ? (
            <h1 className="sub-title">{sub_title}</h1>
          ) : (
            <Fragment>
              <h1 className="title">{title}</h1>
              <h2 className="sub-title">{sub_title}</h2>
            </Fragment>
          )}
          {imageBeforeText && (
            <img
              src={ background_image?.source_url }
              className="hero-image"
              role="presentation"
            />
          )}
          {copy && (
            <p className="copy" dangerouslySetInnerHTML={ { __html: copy } } />
          )}
        </div>

        {!imageFillBackground && !imageBeforeText && background_image && showImage && (
          <img
            src={ background_image?.source_url }
            className="hero-image"
            role="presentation"
          />
        )}
      </div>
      {imageFillBackground && background_image && (
        <img
          src={ background_image?.source_url }
          className={ classNames({ imageFillBackground: true }) }
          role="presentation"
        />
      )}
    </section>
  );
};
