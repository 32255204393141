import Layout from 'layouts/default';

import Wysiwyg from 'components/content-blocks/partials/wysiwyg';
import Yoast from 'components/globals/yoast';
import { HeroDefaultPage } from 'components/hero/default-page';

import './styles/default-page.scss';
/*
This is a basic page template. 
In order for this template to work, don't create a file in /page that controls a page in wordpress.
If pages are created in gatsby then it will stop listening to this page template all together.
The following pages use the template:

*/

export default function DefaultPage({ pageContext }) {
  return (
    <Layout>
      <Yoast { ...pageContext.yoast_head_json } />
      <div className="default-page-template-container" data-section="default page container" data-signal={ pageContext.wordpress_id }>
        <HeroDefaultPage { ...pageContext.acf.default_page_hero } imageFillBackground />
        <section className={ `section ${pageContext.slug}` } >
          <div className="container">
            <Wysiwyg content={ pageContext.content } />
          </div>
        </section>
      </div>
    </Layout>
  );
}
